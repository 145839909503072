const BAYER_CLIENT_ID = '773a7670-64ca-4869-be37-9c75ad240feb'
export const IS_BAYER =
  import.meta.env.VITE_MSAL_CLIENT_ID === BAYER_CLIENT_ID ||
  window.ENV.MSAL_CLIENT_ID === BAYER_CLIENT_ID ||
  (window.ENV.API_MONOLITH_BASE_URL && window.ENV.API_MONOLITH_BASE_URL.includes('bayer'))

export const IS_BASF =
  (import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL && import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL.includes('basf')) ||
  (window.ENV.API_MONOLITH_BASE_URL && window.ENV.API_MONOLITH_BASE_URL.includes('basf'))

export const IS_SAIFTY_CLOUD =
  import.meta.env.VITE_AUTH0_DOMAIN?.includes('saifty.cloud') || window.ENV.AUTH0_DOMAIN?.includes('saifty.cloud')

export const IS_BYK =
  (import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL && import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL.includes('byk')) ||
  (window.ENV.API_MONOLITH_BASE_URL && window.ENV.API_MONOLITH_BASE_URL.includes('byk'))

export const IS_HERAEUS =
  (import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL &&
    import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL.includes('heraeus')) ||
  (window.ENV.API_MONOLITH_BASE_URL && window.ENV.API_MONOLITH_BASE_URL.includes('heraeus'))

export const IS_LANXESS =
  (import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL && import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL.includes('lnx')) ||
  (window.ENV.API_MONOLITH_BASE_URL && window.ENV.API_MONOLITH_BASE_URL.includes('lnx')) ||
  (import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL &&
    import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL.includes('lanxess')) ||
  (window.ENV.API_MONOLITH_BASE_URL && window.ENV.API_MONOLITH_BASE_URL.includes('lanxess'))

const commonRestrictedRoutes = ['query-builder', 'query-builder-id', 'ai-chat']

export function restrictedRoutesByCustomer() {
  let routesToRestrict: string[] = []

  if (IS_BASF) {
    routesToRestrict = commonRestrictedRoutes
  } else if (IS_SAIFTY_CLOUD) {
    routesToRestrict = commonRestrictedRoutes
  } else if (IS_LANXESS) {
    routesToRestrict = [...commonRestrictedRoutes, 'assessment']
  }

  return routesToRestrict
}

export const BASF_COATINGS_BUSINESS_UNITS = ['ZCQ-100', 'ZCQ-300']
